header {
    height: 100vh;
    min-height: 800px;
    padding-top: 7rem;
    overflow: hidden;
}
.header__container {
    text-align: center;
    height:100%;
    position:relative;
}

                    /*    CTA     */

.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

                        /* Socials   */
.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
}
.header__socials::after {
    content: "";
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}

                    /*              ME        */
.me{
    background: linear-gradient(var(--color-primary), transparent);
    width: 22rem;
    height:30rem;
    position:absolute;
    left: calc(50% - 11rem);
    margin-top: 4rem;
    border-radius: 12rem 12rem 0 0;
    overflow: hidden;
    padding: 5rem 1.5rem 1.5rem 1.5rem
}

                 /* Scroll down */   
.scroll__down{
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem ;
}

                         /*Media queries */
@media screen and (max-width : 1024px) and (min-width:600px) {
    header {
        height: 80vh;
        min-height: 800px;
    }
}

@media screen and (max-width : 600px) {
    header{
        height: 100vh;
        min-height: 700px;
        margin: 0 auto;
        padding-top: 3rem;
    }
    .header__socials, .scroll__down {
        display: none;
    }
    .cta{
        gap: 0.5rem;
        margin-top: 1.5rem;
    }
    .me{
        margin-top: 2rem;
    }
}