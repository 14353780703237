.portfolio__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2.5rem;
}

.portfolio__item {
    background: var(--color-bg-variant);
    display: grid;
    padding: 1.2rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}
.portfolio__item:hover{
    border-color: var(--color-primary-variant);
    background: transparent;
}

.portfolio__item-image{
    border-radius: 1.5rem;
    overflow: hidden;
}
.portfolio__item h3 {
    margin: 1rem 0;
}
.build__technologies {
    color: var(--color-light);
    margin-bottom: 0.5rem;
}
.description{
    padding: .5rem 0;
    font-size: 14px;
}
.portfolio__item-cta{
    display: flex;
    align-self: flex-end;
    gap: 1rem;
    margin-bottom: 1rem;
}

                         /*Media queries */
 @media screen and (max-width : 1024px) {
    .portfolio__container{
        gap: 1.2rem;
    }
 }
                        
@media screen and (max-width : 600px) {
    .portfolio__container{
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}