.about__container {
    display: grid;
    width: 50%;
}

.about__content p {
    margin: 2rem 0 2.6rem;
    color: var(--color-light);
}

                         /*Media queries */
@media screen and (max-width : 1024px) {
    .about__container {
        width: 80%;
    }
    .about__content p {
        margin: 1rem 0 1.5rem;
    }
}
                        
@media screen and (max-width : 600px) {
    .about__content {
        text-align: center;
    }
    .about__content p{
        margin: 1.5rem 0;
        font-size: 14px;
        padding: 0 0.5rem;
    }
}